<template>
	<div class="mainBox">
		<div class="formBox">
			<van-form>
				<div class="title">尊敬的起零客户：晟麦SanMark<br>感谢您百忙之中抽空配合完成本次调查问卷。</div>

				<div>
					<div class="title">您是否企业负责人<span style="color:orange">于红丹经理</span>本人</div>
					<van-radio-group v-model="checked2" direction="horizontal" checked-color="#000000" icon-size="17px">
						<van-radio name="1">是</van-radio>
						<van-radio name="2">否</van-radio>
					</van-radio-group>
				</div>
				<div>
					<div class="title">您的专属客户经理：<span style="color:orange">于志杰</span>，是否像您清楚描述本次价格调整费用/内容及目前优惠活动</div>
					<van-radio-group v-model="checked" direction="horizontal" checked-color="#000000" icon-size="17px">
						<van-radio name="1">是</van-radio>
						<van-radio name="2">否</van-radio>
					</van-radio-group>
				</div>
				<div>
					<div class="title">您是否确定在本次价格调整前不在续约并自愿放弃本次活动优惠</div>
					<van-radio-group v-model="checked3" direction="horizontal" checked-color="#000000" icon-size="17px">
						<van-radio name="1">是</van-radio>
						<van-radio name="2">否</van-radio>
					</van-radio-group>
				</div>

				<div v-if="checked3=='1'" class="bgbox">起零公司完全尊重贵公司意愿，注：如合约期满后续约将按照调整后的价格计算并无任何优惠。</div>
				<div v-if="checked3=='2'" class="bgbox">请在2024.4.25 23:59:59前完成续约，目前优惠活动：续约一年加赠2个月，续约两年加赠4个月及SSL证书一年</div>

				<div style="margin: 16px;">
					<van-button round block type="primary" native-type="submit" color="#000000" @click="login"
						:loading="loading">
						提交问卷
					</van-button>
				</div>
			</van-form>
		</div>

		<div class="footer">&copy;ISTERO<div class="istero" @click="toistero">起零公司提供技术支持</div>
		</div>

		<van-action-sheet v-model:show="show" title="反馈">
			<div class="content">
				<van-field v-model="message" rows="10" autosize label="" type="textarea"
					placeholder="您有任何想法/建议可在此处进行反馈" />
				<div class="btnBox">
					<van-button block color="#000000" @click="handelFeedBack" round>提交反馈</van-button>
				</div>
			</div>
		</van-action-sheet>

	</div>
</template>
<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {
		showToast
	} from 'vant';
	import {
		showNotify
	} from 'vant';


	import {
		verifyCard,
		verifyOrder,
		getBanner,
		feedBack
	} from '@/api/home';
	export default {
		name: 'App',
		data() {
			return {
				checked: "",
				checked2: "",
				checked3: "",
			}
		},
		created() {
			let that = this
			that.getBanner()
		},
		setup() {
			const router = useRouter()
			const route = useRoute()

			pushWithQuery()

			function pushWithQuery() {
				if (localStorage.getItem("access_token")) {
					router.push({
						path: "/change"
					})
				}

			}
		},
		methods: {
			toistero() {
				window.location = 'https://www.istero.com'
			},
			getBanner() {
				let that = this
				getBanner({}).then(res => {
					if (res.code == 200) {
						that.banner = res.data.zb_img
					}
				})
			},
			handelFeedBack() {
				let that = this
				if (!that.message) {
					showNotify("请输入反馈内容")
				} else {
					feedBack({
						message: that.message
					}).then(res => {
						if (res.code == 200) {
							that.message = ""
							that.show = false
							showNotify({
								message: "感谢您的反馈，我们将尽快查看",
								type: "success"
							})
						}
					})

				}
			},
			login() {
				let that = this
				if (!that.checked || !that.checked2 || !that.checked3) {
					showNotify("请完成选项")
					return false
				} else {
					that.loading = true
					showNotify({
						message: '提交成功,感谢您的配合,祝您生活愉快',
						type: 'success',
						duration: 5000
					});
					// verifyCard({
					// 	card: that.card,
					// 	password: that.password
					// }).then(res => {
					// 	that.loading = false
					// 	if (res.code == 200) {
					// 		localStorage.clear();
					// 		localStorage.setItem("access_token", "");
					// 		localStorage.setItem("access_token", res.data);
					// 		showNotify({
					// 			message: '验证成功',
					// 			type: 'success',
					// 			duration: 1000
					// 		});
					// 		that.loading = false
					// 		that.$router.push({
					// 			path: "/change"
					// 		})
					// 	}
					// })
				}
			},
			order() {
				let that = this
				if (!that.tel) {
					showNotify('收件号码不能为空')
					return false
				} else {
					that.loading2 = true
					verifyOrder({
						// username: that.username,
						tel: that.tel
					}).then(res => {
						that.loading2 = false
						if (res.code == 200) {
							showNotify({
								message: '已找到订单',
								type: 'success',
								duration: 1000
							});
							that.$router.push({
								path: "/order",
								query: {
									username: that.username,
									tel: that.tel
								}
							})
						}
					})
				}
			}
		}


	}
</script>
<style>
	body {
		background: rgb(24, 24, 24);
	}

	.mainBox {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}



	.mainBox .formBox {
		width: 85%;
		padding: 20px;
		background: #ffffff;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		border-radius: 10px;


	}

	.mainBox .feedback {
		position: absolute;
		right: 0px;
		top: 10%;
		background: #a98146;
		padding: 5px 10px;
		font-size: 12px;
		border-radius: 10px 0px 0px 10px;
	}

	.mainBox .title {
		font-size: 14px;
		padding: 15px 0px;
	}

	.mainBox .footer {
		font-size: 12px;
		padding: 30px 0px;
		color: #676767;
		text-align: center;
	}

	.mainBox .footer .istero {
		font-size: 11px;
		border-bottom: #4c4c4c solid 1px;
		margin-top: 5px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}

	.mainBox .btnBox {
		width: 95%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}
	
	.bgbox{
		background: #f1f1f1;
		padding: 15px;
		font-size: 12px;
		margin-top: 10px;
	}
	
	.van-radio{
		font-size: 14px;
	}
</style>